import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import MarkerPopup from "./components/PopUp/TrackerMarkerPopUp";
import { remove } from "./trackerWindowsSlice";

export default function TrackerWindow(props) {
  const dispatch = useAppDispatch();

  const activeTrackers = useAppSelector((state) => state.trackerWindows);
  const trackers = useAppSelector((state) => state.trackers);

  return (
    <>
      {activeTrackers.map((id) => {
        const info: Tracker = trackers[id];
        console.log(info?.ID);
        return (
          <MarkerPopup
            key={id}
            lat={info?.Latitude}
            lon={info?.Longitude}
            name={info?.ID}
            tracker={info}
            onClosed={() => dispatch(remove(info?.IMEI))}
            config={props.config}
            open={true}
          />
        );
      })}
    </>
  );
}

export interface Tracker {
  MessageType: string;
  DeviceName: string;
  IMEI: string;
  ReportID?: string;
  ReportType?: string;
  NumberOfReports?: string;
  HDOP?: string;
  Speed: string;
  Heading: string;
  Altitude?: string;
  Longitude: string;
  Latitude: string;
  GPSFixTime?: string;
  SatCnt?: string;
  "AccelX:AccelY:AccelZ"?: string;
  GetResponseCount?: string;
  RxTimeSlot?: string;
  BatteryStatus?: string;
  GetResponseErrorCount?: string;
  PacketCRC?: string;
  ID: string;
  Status: string;
  OnSite: boolean;
  Track: Geofence[];
  Approach: Geofence[];
  Category: string;
  alarmTime?: number;
  alarmAckTime?: number;
  time?: number;
  currentTrackTime?: number;
  processTime?: number;
}

export interface Approach {
  type?: string;
  geometry?: Geometry;
  properties?: Properties;
}

export interface Geofence {
  Geofence: string;
  TrackPrefix: string;
}

export interface Geometry {
  type?: string;
  coordinates?: Array<Array<number[]>>;
}

export interface Properties {
  AbsoluteMaximum?: string;
  AlarmState?: string;
  Autocad_layer?: string;
  BatteryWarningLevel?: string;
  Count?: number;
  Direction?: string;
  DisableAllTrackAlarms?: string;
  ExcessStopAlarm?: string;
  ExcessStopLimit?: string;
  Exclusive?: string;
  "HPG number"?: string;
  InternalAlarms?: string;
  MaxNumber?: string;
  OffRoadTime?: string;
  OffTrackAlarm?: string;
  SitePrefix?: string;
  SpeedAlarm?: string;
  SpeedLimit?: string;
  Subpart?: string;
  Subtype?: string;
  Timestamp?: Date;
  TooCloseAlarm?: string;
  TooCloseDistance?: string;
  TooManyVehiclesAlarm?: string;
  "Track/area"?: string;
  TrackColor?: string;
  TrackNo?: string;
  TrackPrefix?: string;
  TrackStatus?: string;
  Type?: string;
  UniqueName?: string;
  "Seq. Number"?: string;
  "Track/Hällered subpart"?: string;
}
