import React, { useCallback, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import MenuList from "@mui/material/MenuList";
import Modal from "@mui/material/Modal";
import Popper from "@mui/material/Popper";
import Alert from "@mui/material/Alert";
import MenuInfo from "../src/components/MenuInfo/MenuInfo";
import MenuInfoOld from "../src/components/MenuInfoOld/MenuInfoOld";
import Geofence from "../src/components/Geofence/Geofence";

import TrackerInfo from "../src/components/TrackerInfo/TrackerInfo";
import Header from "../src/components/Header/Header";
import MapElement from "../src/components/Map/Map";
import loadWebsocket from "../src/components/websocket/websocket";
import Context from "../src/context";
import selectedTrackContext from "../src/selectedTrackContext";
import selectedTrackViewContext from "../src/selectedTrackViewContext";
import allTrackViewsContext from "../src/allTrackViewsContext";
import goToTrackViewContext from "../src/goToTrackViewContext";
import selectedTrackerTabContext from "../src/selectedTrackerTabContext";
import EmergencyButtons from "../src/components/Emergency/EmergencyButtons";
import axios from "axios";
import LightPanel from "../src/components/LightPanel/LightPanel";
import getConfig from "../src/config/config";
import { updateTrack } from "../src/components/Header/HeaderSlice";
import store from "../src/reduxStore";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import ReportIcon from "@mui/icons-material/Report";
import Head from "next/head";
import { unstable_getServerSession } from "next-auth/next";
import { authOptions } from "./api/auth/[...nextauth]";
import getPermissions from "../lib/auth/permissions";
import { signIn, useSession } from "next-auth/react";

import TrackerWindows from "../src/TrackerWindows";

import { set as setPermissions } from "../src/permissionsSlice";
import { DraggableWindow } from "../src/components/DraggablePopup/DraggablePopup";
import ReplayControlsWindow from "../src/components/ReplayControls/ReplayControls";
import { useAppDispatch, useAppSelector } from "../src/hooks/reduxHooks";
import { update as updateLights } from "../src/redux/slices/lights";
import { getReplayConfig } from "../lib/replayConfig";
import { set as setReplayConfig } from "../src/redux/slices/replay";
import { Socket } from "socket.io-client";

import { clear as clearWindows } from "../src/trackerWindowsSlice";
import { clear as clearTrackers } from "../src/components/Map/Marker/trackerSlice";

function getStatus(json) {
  // console.log(json.services);
  let status = "OK";
  Object.keys(json.services).forEach(function (k) {
    if (json.services[k] !== "OK") {
      // console.log(json.services[k]);
      status = "fail";
    }
  });
  return status;
}

export async function getServerSideProps(context) {
  const session = await unstable_getServerSession(
    context.req,
    context.res,
    authOptions
  );

  const username = session?.user?.name ?? "";
  const permissions = JSON.parse(
    JSON.stringify(await getPermissions(username))
  );

  const config = getConfig();
  const replay = { ...getReplayConfig(), isFromRemote: true };

  const replays = JSON.parse(
    JSON.stringify(
      await (async () => {
        try {
          const res = await axios.get(config.url.API + "/get-replays");
          if (res.status === 200) {
            return res.data;
          }
        } catch (e) {
          console.error(e);
        }
        return { replays: [] };
      })()
    )
  );

  return {
    props: { config, permissions, defaultReplays: replays, replay },
  };
}

export default function IndexAuth(props) {
  const sessionStatus = useSession({
    required: true,
    onUnauthenticated() {
      signIn("LDAP").then((r) => console.log(r));
      // The user is not authenticated, handle it here.
    },
  }).status;

  store.dispatch(setPermissions(props.permissions));
  // store.dispatch(toggleReplay(props.isReplay))
  console.log(props.permissions);

  if (sessionStatus === "loading") {
    return "Checking authorization...";
  }

  return <Index {...props} />;
}

function Index(this: any, props) {
  const { config, permissions } = props;

  useEffect(() => {
    if (props.replay.isReplay) {
      console.log(props.replay);
      store.dispatch(setReplayConfig(props.replay));
    }
  }, []);

  // const [websocketLoaded, setWebsocketLoaded] = useState(false)

  // loadWebsocket(config)
  // setWebsocketLoaded(true)
  const replaySettings = useAppSelector((state) => state.replay);

  // const position = { x: 0, y: 100 }
  // const trackers = useSelector((state) => state.trackers.trackers)

  // console.log(trackers)
  // const trackView = useSelector((state) => state.currentTrack)
  // console.log(trackView)
  const [open, setOpen] = React.useState(false);
  const [modalOpen, handleModalOpen] = React.useState(false);
  const [oldmodalOpen, handleoldModalOpen] = React.useState(false);
  const [geofenceOpen, handleGeofence] = React.useState(false);
  const [emergencyOpen, setEmergencyOpen] = React.useState(false);
  const [trackerInfoOpen, handleTrackerInfoOpen] = React.useState(false);
  const [choseOldItem, setChoseOldItem] = React.useState({
    id: undefined,
    menu: undefined,
  });
  const [activeMenu, setActiveMenu] = React.useState({
    id: undefined,
    menu: undefined,
  });
  const [menuItems, setMenuItems] = React.useState({});
  const [leftSelectedItem, makeLeftSelection] = React.useState("HPG");
  const [isLoading, setLoading] = useState(false);
  const [snackBar, showSnackbar] = useState(false);
  const [isShownLightPanel, toggleLightPanel] = useState(false);
  const [originalCopy, setOriginalCopy] = useState<any>(null);
  const [usrPermission, setUsrPermission] = useState("r");
  const [UniqueNameArray, setUniqueNameArray] = useState([]);
  const [selectedTrack, setSelectedTrack] = useState("HPG");
  const [selectedTrackView, setSelectedTrackView] = useState([
    12.745635951226632, 57.773818997544254, 14.0036406, -72.999999999,
  ]);
  const [view, setView] = useState("HPG");
  const [servicesJSON, setServicesJSON] = React.useState({});
  const [status, setStatus] = React.useState("");
  const [goToTrackView, setGoToTrackView] = useState(0);
  const [selectedTrackerTab, setSelectedTrackerTab] = useState("");
  const [dirCTC, setDirCTC] = useState("Clockwise");
  // const [dirDBT, setDirDBT] = useState('Clockwise')
  // const [dirCR, setDirCR] = useState('Clockwise')
  // const [dirGR, setDirGR] = useState('Clockwise')
  // const [dirHT1, setDirHT1] = useState('Clockwise')

  const [trackDirections, setTrackDirections] = useState({
    CTC: "Clockwise",
    DBT: "Clockwise",
    CRT: "Clockwise",
    GR: "Clockwise",
    HT1: "Clockwise",
  });

  const [allTrackViews, setAllTrackViews] = useState({
    HPG: {
      longitude: 12.745636,
      latitude: 57.77382,
      zoom: 14,
      maxPitch: 0,
      bearing: 287,
    },
    AZ: {
      longitude: 12.76884,
      latitude: 57.781254,
      zoom: 14.5,
      maxPitch: 0,
      bearing: 242,
    },
    SKID: {
      longitude: 12.743172,
      latitude: 57.772074,
      zoom: 16.5,
      maxPitch: 0,
      bearing: 242,
    },
    HST: {
      longitude: 12.746044,
      latitude: 57.772499,
      zoom: 14.5,
      maxPitch: 0,
      bearing: 287,
    },
    HT2: {
      longitude: 12.750847,
      latitude: 57.775896,
      zoom: 15.5,
      maxPitch: 0,
      bearing: 287,
    },
    HT1: {
      longitude: 12.742987,
      latitude: 57.773815,
      zoom: 16.5,
      maxPitch: 0,
      bearing: 287,
    },
    DBT: {
      longitude: 12.744514,
      latitude: 57.778433,
      zoom: 16.0,
      maxPitch: 0,
      bearing: 287,
    },
    GR: {
      longitude: 12.739164,
      latitude: 57.780415,
      zoom: 16.5,
      maxPitch: 0,
      bearing: 287,
    },
    CRT: {
      longitude: 12.748319,
      latitude: 57.779718,
      zoom: 14.2,
      maxPitch: 0,
      bearing: 287,
    },
    "B/H": {
      longitude: 12.737568,
      latitude: 57.77284,
      zoom: 15.5,
      maxPitch: 0,
      bearing: 287,
    },
    SLS: {
      longitude: 12.741906,
      latitude: 57.784626,
      zoom: 17,
      maxPitch: 0,
      bearing: 287,
    },
    RR: {
      longitude: 12.741197,
      latitude: 57.764633,
      zoom: 16.4,
      maxPitch: 0,
      bearing: 330,
    },
    ENTT: {
      longitude: 12.732048,
      latitude: 57.770871,
      zoom: 16.5,
      maxPitch: 0,
      bearing: 287,
    },
    CTC: {
      longitude: 12.736849,
      latitude: 57.76666,
      zoom: 16.8,
      maxPitch: 0,
      bearing: 287,
    },
    CS: {
      longitude: 12.745232,
      latitude: 57.775792,
      zoom: 17.0,
      maxPitch: 0,
      bearing: 287,
    },
    COT: {
      longitude: 12.742662,
      latitude: 57.765057,
      zoom: 16.0,
      maxPitch: 0,
      bearing: 330,
    },
    CT: {
      longitude: 12.736486,
      latitude: 57.768027,
      zoom: 16,
      maxPitch: 0,
      bearing: 287,
    },
  });

  const handleSelectMenu = (menu) => {
    setOpen(false);
    setActiveMenu(menu);
    // const originalCopy = JSON.parse(JSON.stringify(menu))
    // setOriginalCopy(originalCopy)
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    showSnackbar(false);
  };

  useEffect(() => {
    if (!replaySettings.isReplay || Object.keys(menuItems).length <= 0) {
      axios
        .get(`${config.url.API}/json`, {
          headers: { accept: "application/json" },
        })
        .then((data) => {
          const items = data.data ? data.data.items : {};
          // console.log(items);
          const usrPermission = data.data.usrPermission || "r";
          setUsrPermission(usrPermission);
          setDirCTC(items.CTC["track-level"][5].value);
          setMenuItems(items);
          initializeTrackDirections(items);
        });
    }
  }, []);

  function initializeTrackDirections(json) {
    const tracks = ["CTC", "DBT", "CRT", "GR", "HT1"];
    const trackDirs = trackDirections;
    Object.keys(json).forEach((element) => {
      if (tracks.includes(element)) {
        for (let i = 0; i < json[element]["track-level"].length; i++) {
          if (json[element]["track-level"][i].name === "Direction") {
            trackDirs[element] = json[element]["track-level"][i].value;
          }
        }
      }
    });
    setTrackDirections(trackDirs);
  }

  useEffect(() => {
    axios.get(`${config.url.API}/services`).then((data) => {
      const json = data.data;
      // console.log(json);
      setServicesJSON(json);
      setStatus(getStatus(json));
    });
  }, []);

  useEffect(() => {
    axios.get(`${config.url.API}/track-views`).then((data) => {
      const json = data.data;
      console.log(json);
      setAllTrackViews(json);
    });
  }, []);

  useEffect(() => {
    connectMenuEvent();
  }, []);

  function connectMenuEvent() {
    const servicesEvent = new EventSource(
      `${config.url.API}/menu-services-events`
    );
    servicesEvent.onmessage = (e) => {
      const json = JSON.parse(e.data);
      if (json.services) {
        // console.log("Services update received");
        setServicesJSON(json);
        setStatus(getStatus(json));
      }
      if (json.views) {
        setAllTrackViews(json.views);
      }
      if (json.items) {
        if (replaySettings.isReplay && Object.keys(menuItems).length > 0) {
          return;
        }
        setMenuItems(json.items);
        initializeTrackDirections(json.items);
      }
    };
    servicesEvent.onerror = (e) => {
      servicesEvent.close();
      setTimeout(() => {
        console.log("Reconnecting Menu Event");
        connectMenuEvent();
      }, 1000);
    };
  }

  const modifyArr = (arr, item, kind, event) => {
    return arr.map((i) => {
      if (i.name == item.name) {
        if (kind == "toggle") {
          i.value = !i.value;
        }
        if (kind == "select") {
          i.value = event.target.value;
        }
        if (kind == "number") {
          // console.log(arr, item, kind)
          if (item.name == "MaxNumber") {
            const abMax = arr.filter((o) => o.name == "AbsoluteMaximum")[0];
            if (abMax && Number(event.target.value) > abMax.value) {
              alert("Max Number cannot be greater than Absolute Maximum.");
              return i;
            }
          }

          if (
            Number(event.target.value) > item.max ||
            Number(event.target.value) < item.min
          ) {
            alert(`Please enter number between ${item.min} and ${item.max}`);
            // i.value = i.value
          } else {
            i.value = Number(event.target.value);
          }
        }
      }
      return i;
    });
  };

  const handleChange = (isOld = "new", menu, kind, item, event) => {
    let menuObj: { id: undefined; menu: undefined };
    if (isOld == "old") {
      menuObj = choseOldItem;
    } else {
      menuObj = activeMenu;
    }

    const obj = { ...menuObj };
    obj[menu] = modifyArr(obj[menu], item, kind, event);

    if (isOld == "new") {
      setActiveMenu(obj);
    } else {
      setChoseOldItem(obj);
    }
  };

  const handleSubmit = (isOld = "new") => {
    setLoading(true);
    setTimeout(() => {
      try {
        // update menu json
        console.log(choseOldItem);
        axios
          .post(
            `${config.url.API}/json`,
            isOld === "new" ? activeMenu : choseOldItem
          )
          .then((post) => {
            setDirectionState(choseOldItem);
            sendPLCPost(choseOldItem).then(() => {
              setOriginalCopy(null);
              showSnackbar(true);
              handleoldModalOpen(false);
            });
          });
      } catch (e) {
        alert("error");
      }
      setLoading(false);
    }, 1000);
  };

  async function sendPLCPost(json) {
    if (config.plcDisabled === "true") {
      return;
    } else {
      const post = await axios.post(`${config.url.PLC}/alarmsTabUpdate`, json);
    }
  }

  function setDirectionState(json) {
    const trackDirs = trackDirections;
    for (let i = 0; i < json["track-level"].length; i++) {
      if (json["track-level"][i].name === "Direction") {
        trackDirs[json.menu] = json["track-level"][i].value;
      }
    }
    setTrackDirections(trackDirs);
  }

  const setOldMenuItem = (item, menu) => {
    handleoldModalOpen(true);
    const originalCopy = JSON.parse(JSON.stringify({ ...item, menu }));
    setOriginalCopy(originalCopy);
    setChoseOldItem({ ...item, menu });
  };

  const handleLeftClick = (item, menuKey) => {
    makeLeftSelection(item);
    // console.log(menuKey);
    setView(menuKey);
    store.dispatch(updateTrack(menuKey));
  };

  const resetToOriginal = () => {
    if (originalCopy) {
      const obj = { ...menuItems };
      obj[originalCopy.menu] = originalCopy;
      setMenuItems(obj);
      setOriginalCopy(null);
    }
  };

  const [lightPanelInfo, setLightPanelInfo] = useState(null);

  useEffect(() => {
    axios.get(`${config.url.API}/lightpanel`).then((data) => {
      const panelData = JSON.parse(data.data);
      setLightPanelInfo(panelData.items);
      store.dispatch(updateLights(panelData.items));
    });
    // setPermission(panelData.UserPlcAccess)
  }, []);

  /*
    useEffect(() => {
        const lightsEvent = new EventSource(`${config.url.API}/lights-events`)
        lightsEvent.onmessage = (e) => {
            const json = JSON.parse(e.data)
            // console.log(json);
            if (json.items) {
                // console.log("Light panel update received");
                // console.log(json.items);
                setLightPanelInfo(json.items)
            }
        }
    }, [])
    */

  const [ws, setWS] = useState<Socket>();

  useEffect(() => {
    const socket = loadWebsocket(config);
    socket.on("trackParameters", (data) => {
      const { items } = JSON.parse(data);
      setMenuItems((old) => {
        // we set the new items using the keys from the old items so we keep the buttons in the same order.
        return Object.fromEntries(
          Object.keys(old).map((key) => [key, items[key]])
        );
      });
      // if(activeMenu)
    });
    setWS(socket);
    return () => {
      console.log("closing websocket");
      socket?.off("connect");
      socket?.off("disconnect");
      socket?.off("close");
      socket?.off("tracker");
      socket?.off("alarm");
      socket?.off("barrierRequest");
      socket?.off("array");
      socket?.off("barriers");
      socket?.off("categories");
      socket?.off("dirmax");
      socket?.off("replay-response");
      socket?.off("trackParameters");
      socket?.off("replay-peer");
      setWS(socket);
    };
  }, []);

  const changeOldItemCallback = useCallback(
    (newItems) => {
      console.log("changeOldItemCallback");
      if (replaySettings.isReplay && !!choseOldItem?.menu) {
        console.log("updating menu");
        setChoseOldItem((old) => ({
          ...newItems[old.menu ?? ""],
          menu: old.menu,
        }));
      }
    },
    [choseOldItem, replaySettings]
  );

  useEffect(() => {
    console.log("menu items", menuItems);
    changeOldItemCallback(menuItems);
  }, [menuItems]);

  const updateReplayPeer = useCallback(
    (isReplay) => {
      ws?.emit("replay-peer", { ...replaySettings, isReplay: isReplay });
    },
    [replaySettings]
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    handleGeofence(false);
    handleTrackerInfoOpen(false);
    toggleLightPanel(false);
    dispatch(clearWindows());
    dispatch(clearTrackers());
  }, [replaySettings.isReplay]);

  return (
    <Context.Provider value={[UniqueNameArray, setUniqueNameArray]}>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <title>RTS vMonitor</title>
      </Head>
      <selectedTrackContext.Provider value={[selectedTrack, setSelectedTrack]}>
        <selectedTrackViewContext.Provider
          value={[selectedTrackView, setSelectedTrackView]}
        >
          <allTrackViewsContext.Provider
            value={[allTrackViews, setAllTrackViews]}
          >
            <goToTrackViewContext.Provider
              value={[goToTrackView, setGoToTrackView]}
            >
              <selectedTrackerTabContext.Provider
                value={[selectedTrackerTab, setSelectedTrackerTab]}
              >
                <Header
                  permissions={props?.permissions}
                  config={config}
                  selectedTrack={selectedTrack}
                  selectedTrackView={selectedTrackView}
                  geofenceOpen={geofenceOpen}
                  handleGeofence={handleGeofence}
                  modalOpen={modalOpen}
                  handleModalOpen={handleModalOpen}
                  menuItems={menuItems}
                  setOldMenuItem={setOldMenuItem}
                  handleLeftClick={handleLeftClick}
                  leftSelectedItem={leftSelectedItem}
                  toggleLightPanel={(e) => {
                    toggleLightPanel(!isShownLightPanel);
                  }}
                  servicesJSON={servicesJSON}
                  status={status}
                />
                <div style={{ zIndex: 99 }}>
                  <Modal
                    open={modalOpen}
                    onClose={() => {
                      handleModalOpen(false);
                    }}
                    disableAutoFocus={true}
                  >
                    <div className="menu-main">
                      <div className="menu-items">
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                        >
                          {Object.keys(menuItems).map((menuKey) => {
                            return (
                              <MenuItem
                                key={menuItems[menuKey].id}
                                className={
                                  activeMenu?.id === menuItems[menuKey].id
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  handleSelectMenu({
                                    ...menuItems[menuKey],
                                    menu: menuKey,
                                  })
                                }
                              >
                                {menuKey} Alarm Control
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </div>
                      <div className="menu-container">
                        <MenuInfo
                          usrPermission={usrPermission}
                          menu={activeMenu}
                          handleChange={handleChange.bind(this, "new")}
                          handleSubmit={handleSubmit.bind(this, "new")}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  </Modal>

                  <Modal
                    open={oldmodalOpen}
                    onClose={() => {
                      handleoldModalOpen(false);
                      resetToOriginal();
                    }}
                    disableAutoFocus={true}
                  >
                    <div className="old-menu-main">
                      <MenuInfoOld
                        usrPermission={usrPermission}
                        resetToOriginal={resetToOriginal}
                        handleModalOpen={handleoldModalOpen}
                        menu={choseOldItem}
                        handleChange={handleChange.bind(this, "old")}
                        handleSubmit={handleSubmit.bind(this, "old")}
                        isLoading={isLoading}
                        config={config}
                      />
                    </div>
                  </Modal>
                  <Snackbar
                    open={snackBar}
                    autoHideDuration={3000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={() => handleClose("close", "click")}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Updated!
                    </Alert>
                  </Snackbar>
                </div>
                <div
                  className={"mapDiv"}
                  style={{ height: `${window.innerHeight - 80} px` }}
                >
                  <MapElement
                    config={config}
                    selectedTrack={selectedTrack}
                    data={UniqueNameArray}
                    dirCTC={dirCTC}
                    trackDirections={trackDirections}
                    geofenceOpen={geofenceOpen}
                  />
                </div>
                <Button
                  style={{
                    position: "fixed",
                    bottom: 10,
                    top: "unset",
                    left: "unset",
                  }}
                  onClick={() => {
                    handleTrackerInfoOpen(true);
                  }}
                >
                  <InfoIcon></InfoIcon>
                </Button>
                {permissions.canAccessPLC ? (
                  <Button
                    style={{
                      position: "fixed",
                      bottom: 10,
                      top: "unset",
                      left: "96%",
                      color: "#FF0000",
                    }}
                    onClick={() => {
                      setEmergencyOpen(!emergencyOpen);
                    }}
                  >
                    <ReportIcon style={{ fontSize: 35 }}></ReportIcon>
                  </Button>
                ) : null}
                <TrackerInfo
                  config={config}
                  handleModalOpen={handleTrackerInfoOpen}
                  trackerInfoOpen={trackerInfoOpen}
                />
                <Popper
                  open={geofenceOpen}
                  style={{
                    position: "fixed",
                    bottom: 60,
                    top: "unset",
                    left: "unset",
                  }}
                >
                  <div className="geofence">
                    <Geofence
                      config={config}
                      geofenceOpen={geofenceOpen}
                      handleModalOpen={handleGeofence}
                    />
                  </div>
                </Popper>
                <Popper
                  open={emergencyOpen}
                  style={{
                    position: "fixed",
                    bottom: 60,
                    top: "unset",
                    left: "88%",
                  }}
                >
                  <EmergencyButtons
                    config={config}
                    setEmergencyOpen={setEmergencyOpen}
                  />
                </Popper>
                {
                  <DraggableWindow
                    offset={{ x: 20, y: 500 }}
                    open={isShownLightPanel}
                    onClose={() => toggleLightPanel(false)}
                    title={"Light Panel"}
                  >
                    <LightPanel
                      config={config}
                      lightPanelInfo={lightPanelInfo}
                    />
                  </DraggableWindow>
                }
                <ReplayControlsWindow
                  open={replaySettings.controlsOpen}
                  defaultReplays={props.defaultReplays}
                  onLoad={() => {
                    handleGeofence(false);
                    handleTrackerInfoOpen(false);
                    toggleLightPanel(false);
                    updateReplayPeer(true);
                  }}
                  onEnd={() => {
                    updateReplayPeer(false);
                  }}
                />
                <TrackerWindows config={config} />
              </selectedTrackerTabContext.Provider>
            </goToTrackViewContext.Provider>
          </allTrackViewsContext.Provider>
        </selectedTrackViewContext.Provider>
      </selectedTrackContext.Provider>
    </Context.Provider>
  );
}
