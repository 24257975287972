import * as React from "react";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import maplibre from "maplibre-gl";
import Map, {
  FullscreenControl,
  Layer,
  LngLatBoundsLike,
  NavigationControl,
  Source,
  useMap,
  ViewState,
} from "react-map-gl";
import TrackerMarker from "./Marker/TrackerMarker.js";
import BarrierMarker from "./Marker/BarrierMarker";
import DirectionMarker from "./Marker/DirectionMarker";
import axios from "axios";
import barriersInit from "../../geojson/barrier-status.json";
import directions from "../../geojson/directions.json";

import selectedTrackContext from "../../selectedTrackContext";
import selectedTrackViewContext from "../../selectedTrackViewContext";
import allTrackViewsContext from "../../allTrackViewsContext";
import goToTrackViewContext from "../../goToTrackViewContext";

import "mapbox-gl/dist/mapbox-gl.css";
import useFullscreenStatus from "../../useFullscreenStatus";
import { useAppSelector } from "../../hooks/reduxHooks";
import useSWR from "swr";
import { Typography } from "@mui/material";

function SelectTrack() {
  const [selectedTrackView, setSelectedTrackView] = useContext(
    selectedTrackViewContext
  );
  const { current: map } = useMap();
  // console.log(map)

  useEffect(() => {
    const obj = {
      longitude: map?.getCenter().lng,
      latitude: map?.getCenter().lat,
      zoom: map?.getZoom(),
      bearing: map?.getBearing(),
    };
    setSelectedTrackView([obj.longitude, obj.latitude, obj.zoom, obj.bearing]);
    // console.log(selectedTrackView)
  }, [map?.getCenter(), map?.getBearing(), map?.getZoom()]);

  // onsole.log(    map.getCenter())
  // console.log(    map.getBearing())
  return null;
}

function GoToTrackView(props) {
  const [goToTrackView, setGoToTrackView] = useContext(goToTrackViewContext);
  const [selectedTrackView, setSelectedTrackView] = useContext(
    selectedTrackViewContext
  );
  const [allTrackViews, setAllTrackViews] = useContext(allTrackViewsContext);
  const [selectedTrack, setSelectedTrack] = useContext(selectedTrackContext);

  const { current: map } = useMap();

  useEffect(() => {
    const temp = allTrackViews;
    if (map) {
      console.log("track long is " + allTrackViews[selectedTrack].longitude);
      console.log("track lat is " + allTrackViews[selectedTrack].latitude);
      console.log("track zoom is " + allTrackViews[selectedTrack].zoom);

      console.log("track bearing is " + allTrackViews[selectedTrack].bearing);

      map?.flyTo({
        center: [
          allTrackViews[selectedTrack].longitude,
          allTrackViews[selectedTrack].latitude,
        ],
        zoom: allTrackViews[selectedTrack].zoom,
        pitch: 0,
        bearing: allTrackViews[selectedTrack].bearing,
      });
    }
  }, [map, selectedTrack, goToTrackView]);
  return null;
}

export default function MapElement(props) {
  const { config } = props;
  const [selectedTrack, setSelectedTrack] = useContext(selectedTrackContext);
  const [allTrackViews, setAllTrackViewsContext] =
    useContext(allTrackViewsContext);
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(true);
  const [geofences, setGeofences] = useState<GeoJSON.FeatureCollection>();

  // const HPG: ViewState = {
  //     longitude: 12.745636,
  //     latitude: 57.773820,
  //     zoom: 14,
  //     pitch: 0,
  //     bearing: 287,
  //     padding: {
  //         top: 0, bottom: 0, right: 0, left: 0
  //     }
  // }

  const [viewState, setViewState] = useState<ViewState>();
  //const [barriersState, setBarriersState] = useState(barriersInit)
  // const [categories, setCategories] = useState()
  // const [barrierData, setBarrierData] = useState<{ [k: string]: Barrier }>({})
  const categories = useAppSelector((state) => state.categories);
  const barrierData = useAppSelector((state) => state.barriers);
  const dirAndMax = useAppSelector((state) => state.dirmax);
  const barriersState = useAppSelector((state) => state.plcBarriersEvent);
  // const [, setDirAndMax] = useState()

  // useEffect(() => {
  //     axios.get(`${config.url.API}/categories`).then(categories => {
  //
  //     //console.log(categories.data.categories)
  //     setCategories(categories.data.categories)
  //     })
  // }, [])

  // useEffect( () => {
  //     axios.get(`${config.url.API}/barriers`).then(barriers => {
  //
  //     // console.log(JSON.parse(barriers.data))
  //     setBarrierData(JSON.parse(barriers.data));
  //     })
  // }, [])

  // const websocketBarrierData = useAppSelector(state => state.barriers)
  // useEffect(() => {
  //     setBarrierData(websocketBarrierData)
  // },[websocketBarrierData])

  // useEffect(() => {
  //     connectPlcEvent();
  // }, [])
  //
  // function connectPlcEvent(){
  //     let plcEvent = new EventSource(`${config.url.API}/barriers-event`)
  //     plcEvent.onopen = (e) =>{
  //         console.log("barriers-event connected");
  //     }
  //     plcEvent.onmessage = (e) => {
  //         const json = JSON.parse(e.data)
  //         console.log(json);
  //         setBarrierData(json)
  //     }
  //     plcEvent.onerror = (err) =>{
  //         setTimeout(() =>{
  //             plcEvent.close();
  //             connectPlcEvent();
  //         },1000)
  //     }
  // }

  // useEffect(() => {
  //     axios.get(`${config.url.API}/dirandmax`).then(data => {
  //
  //     console.log(data.data)
  //     setDirAndMax(data.data);
  //     })
  // }, [])

  // useEffect(() => {
  //     connectDirMaxEvent();
  // }, [])

  // function connectDirMaxEvent(){
  //     const dirMaxEvent = new EventSource(`${config.url.API}/dirMax-event`)
  //     dirMaxEvent.onmessage = (e) => {
  //         const json = JSON.parse(e.data)
  //         console.log(json);
  //         setDirAndMax(json);
  //     }
  //     dirMaxEvent.onerror = (err) => {
  //         setTimeout(() =>{
  //             console.log("Reconnecting Dir-max event");
  //             connectDirMaxEvent();
  //         },1000)
  //     }
  // }

  // console.log(dirAndMax)

  useEffect(() => {
    axios.get(`${config.url.API}/geofence`).then((geofencesInit) => {
      setGeofences(JSON.parse(geofencesInit.data));
    });
  }, [props.geofenceOpen]);

  /*
    useEffect(() => {
        const plcEvent = new EventSource(`${config.url.PLC}/plc-barriers`)
        plcEvent.onmessage = (e) => {
            const json = JSON.parse(e.data)
            // console.log(json);
            updateBarriers(json)
        }
    }, [])

    const updateBarriers = (data) => {
        setBarriersState(data)
    }
 */

  const trackers = useAppSelector((state) => state.trackers);
  const trackView = useAppSelector((state) => state.currentTrack);

  const markers = useMemo(
    () =>
      Object.values(trackers).map((value, index) => {
        return (
          <TrackerMarker
            config={config}
            key={index}
            tracker={value}
            categories={categories}
          />
        );
      }),
    [trackers, categories]
  );

  useEffect(() => {
    setViewState(allTrackViews[trackView]);
    setSelectedTrack(trackView);
  }, [trackView]);

  const barriersGeojson = require("../../geojson/barriers.json");

  // useEffect(() => {
  //     const categoryEvent = new EventSource(`${config.url.API}/category-event`)
  //     categoryEvent.onmessage = (e) => {
  //         const json = JSON.parse(e.data)
  //         console.log(json)
  //         setCategories(json.categories)
  //     }
  // }, [])

  // const [view, setView] = React.useState(HPG)

  const bounds: LngLatBoundsLike = [
    [12.667444, 57.70533], // Southwest coordinates
    [12.842948, 57.845668], // Northeast coordinates
  ];

  const pointJson = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [12.7414821253, 57.7836354398, 0],
    },
  };

  // Used for direction markers, order is: [TrackAbbrev, divisor, remainder]
  const trackChecks: [string, number, number][] = [
    ["CTC", 9, 5],
    ["HT1", 14, 0],
    ["DBT", 32, 18],
    ["GR", 10, 0],
    ["CRT", 25, 17],
  ];

  const [isFullscreen, setIsFullscreen]: any[] = useFullscreenStatus(
    useRef(document.documentElement)
  );
  const [areDirectionMarkersVisible, setAreDirectionMarkersVisible] =
    useState(false);
  useLayoutEffect(() => {
    setAreDirectionMarkersVisible(!isFullscreen);
  }, [isFullscreen]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      //console.log(e)
      if (e.key === "F11") {
        e.preventDefault();
        // This causes intermittent issues when the browser has decided the user hasn't interacted with the page
        // JavaScript code isn't allowed to toggle full screen before user interaction
        setIsFullscreen(!isFullscreen);
      }
    });
  });

  // const [areDirectionMarkersVisible, setAreDirectionMarkersVisible] = React.useState(true)
  // useLayoutEffect(() => {
  //
  // })
  useEffect(() => {});
  const { data: style, isValidating } = useSWR("/map/style.json", {
    fallbackData: "/map/style.json",
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    revalidateOnMount: false,
  });

  const isReplay = useAppSelector((state) => state.replay.isReplay);

  if (isValidating) {
    return <Typography>Loading...</Typography>;
  }
  /* if(loading) { return <div> Loading ... </div> }; */
  return (
    <Map
      mapLib={maplibre}
      reuseMaps={true}
      maxBounds={bounds}
      style={{ width: "100vw", height: `${window.innerHeight - 80}px` }}
      mapStyle={style}
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
    >
      {geofences && <GeofenceDisplay data={props.data} geofences={geofences} />}

      {barrierData
        ? Object.keys(barrierData).map((key) => {
            if (key.split("_").length > 1) {
              return null;
            } else {
              const barrier = barrierData[key];
              return (
                <BarrierMarker
                  config={config}
                  key={key}
                  name={key}
                  barrier={barrier}
                  state={barriersState[key]}
                  isReplay={isReplay}
                  trackData={
                    dirAndMax ? dirAndMax[barrierData[key].trackPrefix] : null
                  }
                />
              );
            }
          })
        : null}

      {markers}

      {trackChecks.map((trackData) => {
        const track = trackData[0];
        const divisor = trackData[1];
        const remainder = trackData[2];
        return directions[track].map((data, i) => {
          if (i % divisor !== remainder) {
            return;
          }
          const { latitude, longitude, rotation } = data;
          return (
            <DirectionMarker
              key={`${track}-${i}`}
              latitude={latitude}
              longitude={longitude}
              allTrackViews={allTrackViews}
              rotation={rotation}
              direction={props.trackDirections[track]}
              track={track}
              visible={areDirectionMarkersVisible}
            />
          );
        });
      })}

      <NavigationControl position={"top-left"} />
      <FullscreenControl position={"top-right"} />
      <SelectTrack></SelectTrack>
      <GoToTrackView></GoToTrackView>
    </Map>
  );
}

function GeofenceDisplay(props: {
  data: any[];
  geofences: GeoJSON.FeatureCollection;
}) {
  const activeIds = props.data;
  const tempObj: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features:
      props.geofences?.features.filter((item) =>
        activeIds.includes(item?.properties?.Autocad_layer)
      ) ?? [],
  };

  return (
    <Source type="geojson" data={tempObj}>
      <Layer
        {...{
          id: "layerStylePolygon",
          type: "fill",
          paint: {
            "fill-color": "#FFA500",
            "fill-opacity": 0.4,
          },
          filter: ["==", "$type", "Polygon"],
        }}
      />
      <Layer
        {...{
          id: "layerStyleLine",
          type: "line",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#FF0000",
            "line-width": 8,
            "line-opacity": 0.4,
          },
        }}
      />
    </Source>
  );
}
