import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import axios from "axios";

export default function EmergencyButtons(props) {
  const { config, setEmergencyOpen } = props;

  const [showAlert, setShowAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [command, setCommand] = useState("");

  function openAll() {
    setDisabled(true);
    setShowAlert(true);
    setCommand("Open");
  }

  function closeAll() {
    setDisabled(true);
    setShowAlert(true);
    setCommand("Close");
  }

  function cancel() {
    setDisabled(false);
    setShowAlert(false);
    setEmergencyOpen(false);
  }

  async function sendPLC() {
    console.log(command);
    const url = `${config.url.PLC}/plc/AllBarrier/` + command;
    console.log("PLC Barrier Command: " + url);
    const response = await axios.get(url);
    setShowAlert(false);
    setDisabled(false);
    setEmergencyOpen(false);
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        padding: "5px",
      }}
    >
      <Stack spacing={1}>
        <Button
          variant={"contained"}
          color={"error"}
          disabled={disabled}
          onClick={() => openAll()}
        >
          Open All
        </Button>
        <Button
          variant={"contained"}
          color={"error"}
          disabled={disabled}
          onClick={() => closeAll()}
        >
          Close All
        </Button>
      </Stack>
      {showAlert ? (
        <Alert
          severity={"warning"}
          open={showAlert}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack spacing={1}>
            <Button variant={"contained"} onClick={() => sendPLC()}>
              Confirm
            </Button>
            <Button variant={"contained"} onClick={() => cancel()}>
              Cancel
            </Button>
          </Stack>
        </Alert>
      ) : null}
    </Box>
  );
}
