import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./LightPanel.module.scss";
import { useAppSelector } from "../../hooks/reduxHooks";

function LightPanel(props) {
  const { config /*,lightPanelInfo*/ } = props;
  console.log(config);
  // const [lightPanelInfo, setLightPanelInfo] = useState({})
  const permissions = useAppSelector((state) => state.permissions);
  const isReplay = useAppSelector((state) => state.replay.isReplay);
  const lightPanelInfo = useAppSelector((state) => state.lightsData);
  const plcStatus = useAppSelector((state) => state.plcStatus.status);
  // const [permission, setPermission] = useState(false)
  const [activePanel, setActivePanel] = useState(null);

  console.log(lightPanelInfo);

  // useEffect(() => {
  //     axios.get(`${config.url.API}/lightpanel`).then(data => {
  //         const panelData = JSON.parse(data.data)
  //         setLightPanelInfo(panelData.items)
  //     })
  //     // setPermission(panelData.UserPlcAccess)
  // }, [])
  //
  // useEffect(() => {
  //     const lightsEvent = new EventSource(`${config.url.API}/lights-events`)
  //     lightsEvent.onmessage = (e) => {
  //         const json = JSON.parse(e.data)
  //         // console.log(json);
  //         if (json.items) {
  //             // console.log("Light panel update received");
  //             // console.log(json.items);
  //             setLightPanelInfo(json.items)
  //         }
  //     }
  // }, [])

  const handleShowPanel = (lightKey) => {
    if (isReplay) {
      return;
    }
    if (lightKey == activePanel) {
      setActivePanel(null);
    } else {
      setActivePanel(lightKey);
    }
  };

  const handleColorChange = async (lightKey, color) => {
    if (!permissions.canAccessPLC || isReplay) {
      return;
    }

    //const data = await axios.post(`${config.url.API}/updateLight`, { lightKey, color })
    const lightName = lightKey.replace(/\s+/g, "");
    color = color.charAt(0).toUpperCase() + color.slice(1);
    // console.log(lightName);
    const response = await axios.get(
      `${config.url.PLC}/plc/` + lightName + "/" + color
    );

    //setLightPanelInfo(data.data.items)
  };

  console.log(permissions);

  return (
    <div className="light-panel">
      <ul className={styles.outerUl}>
        {lightPanelInfo.map((light) => {
          let lightKey = Object.keys(light)[0];
          return (
            <li
              key={lightKey}
              className={`${styles.panelList} ${styles[light[lightKey].selected]}`}
              onClick={(e) => handleShowPanel(lightKey)}
            >
              {lightKey}
              <ul
                onClick={(e) => e.stopPropagation()}
                className={`${styles.panelList} ${activePanel === lightKey ? styles.shown : styles.hidden} ${styles.innerUl}`}
              >
                {light[lightKey].colors.map((color) => {
                  return (
                    <li key={color} className={styles.panelList}>
                      <button
                        className={styles[color.toLowerCase()]}
                        onClick={(e) => handleColorChange(lightKey, color)}
                        disabled={
                          !permissions.canAccessPLC ||
                          plcStatus === "Inactive" ||
                          plcStatus === "Timeout"
                        }
                      >
                        {color}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default LightPanel;
