import React, { useContext, useMemo, useState, useEffect } from "react";
// import { API } from '../../config/config';
import { useSelector } from "react-redux";
import styles from "./TrackerInfo.module.scss";
import TrackerInfoTabs from "./TrackerInfoTabs";
import { DraggableWindow } from "../DraggablePopup/DraggablePopup";
import { useAppSelector } from "../../hooks/reduxHooks";

function TrackerInfo({ handleModalOpen, trackerInfoOpen, config }) {
  // list of unique names for checked data
  const trackers = useSelector((state) => state.trackers);
  const trackView = useSelector((state) => state.currentTrack);
  const categories = useAppSelector((state) => state.categories);

  const [searchItem, setSearchItem] = useState("");
  const [failFilter, setFailFilter] = useState(false);
  const [connectingFilter, setConnectingFilter] = useState(false);

  const handleCloseClick = () => {
    handleModalOpen(false);
  };

  function resetAlarmStates() {}

  function toggleFailFilter() {
    setFailFilter(!failFilter);
    setConnectingFilter(false);
  }

  function toggleConnectingFilter() {
    setConnectingFilter(!connectingFilter);
    setFailFilter(false);
  }

  useEffect(() => {
    if (failFilter) {
      setSearchItem("Fail");
    } else if (connectingFilter) {
      setSearchItem("Connecting");
    } else {
      setSearchItem("");
    }
  }, [failFilter, connectingFilter]);

  const count = useMemo(() => {
    let count = 0;
    Object.keys(trackers).forEach((key) => {
      const tracker = trackers[key];
      const fenceArrayLength = tracker.Track.length;
      let trackTest = true;
      if (fenceArrayLength > 0) {
        trackTest =
          tracker.Track[fenceArrayLength - 1].TrackPrefix === trackView;
      }
      if (trackView === "HPG") {
        trackTest = true;
      }

      if (trackTest) {
        count++;
      }
    });
    return count;
  }, [trackers, trackView]);

  const title = "Tracker Info Display";

  let filteredList = useMemo(() => {
    console.log(trackers);
    if (searchItem === "Fail") {
      return Object.values(trackers).filter(
        (val) => val.Status === "Fail" || val.Status === "GPS-Fail"
      );
    } else if (searchItem === "Connecting") {
      return Object.values(trackers).filter(
        (val) => val.Status === "Connecting"
      );
    } else {
      return Object.values(trackers).filter((val) =>
        searchItem === ""
          ? true
          : val.ID.toLowerCase().includes(searchItem.toLowerCase())
      );
    }
  }, [searchItem, trackers]);

  filteredList?.sort(function (a, b) {
    if (a.ID < b.ID) {
      return -1;
    }
    if (a.ID > b.ID) {
      return 1;
    }
    return 0;
  });
  //console.log(filteredList);

  return (
    <DraggableWindow
      title={title}
      offset={{ x: 20, y: 60 }}
      open={trackerInfoOpen}
      onClose={() => handleModalOpen(false)}
    >
      <div className={styles.trackerInfo}>
        <div className={styles.trackerInfoMain}>
          <ul className="outer-ul-gf">
            <div>No of VTUs {count}</div>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchItem(e.target.value)}
            ></input>{" "}
            Show Fail
            <input
              type="radio"
              checked={failFilter}
              onClick={(e) => toggleFailFilter()}
              readOnly={true}
            ></input>{" "}
            Show Connecting
            <input
              type="radio"
              checked={connectingFilter}
              onClick={(e) => toggleConnectingFilter()}
              readOnly={true}
            ></input>
          </ul>
          <div>
            <table className={styles.trackersTable}>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Category</th>
                <th>Track</th>
                <th>Battery</th>
                <th>SatCnt</th>
                <th>HDOP</th>
                <th>Speed</th>
              </tr>
              {filteredList?.map((tracker) => {
                // console.log(tracker)
                return (
                  <TrackerInfoTabs
                    config={config}
                    key={tracker.IMEI}
                    tracker={tracker}
                    categories={categories}
                  />
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </DraggableWindow>
  );
}

export default TrackerInfo;
