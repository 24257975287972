import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
// import { API } from '../../config/config';
import ArrowIcon from "./../../assets/arrow.svg";
import styles from "./Geofence.module.scss";
import Context from "../../context";
import Image from "next/image";

// import Image from 'next/image'

function Geofence({ handleModalOpen, geofenceOpen, config }) {
  const [geofenceData, setGeofenceData] = useState({});
  const [extended, setExtended] = useState([]);
  const [checkedData, setCheckedData] = useState({});
  const [uniqueNameArray, setUniqueNameArray] = useContext(Context);
  // list of unique names for checked data

  useEffect(() => {
    setUniqueNameArray(
      Object.keys(checkedData).reduce(function (res, v) {
        return res.concat(checkedData[v]);
      }, [])
    );
  }, [checkedData]);

  // Fetch geofence data to display in pop up window
  useEffect(() => {
    const run = async () => {
      const data = await axios.get(`${config.url.API}/geofence`);
      const panelData = JSON.parse(data.data);
      const obj = {};
      const obj2 = {};

      // Use forEach for operations that don't return a value
      panelData.features.forEach((o) => {
        const enhancedProperties = {
          ...o.properties,
          geometryType: o.geometry.type,
        };
        // Determine the trackPrefix for organizing data
        const trackPrefix =
          enhancedProperties.TrackPrefix ?? enhancedProperties["Track/area"];
        if (!obj[trackPrefix]) {
          obj[trackPrefix] = {};
        }

        // Ensure a container exists for this Type under the current trackPrefix
        if (!obj[trackPrefix][enhancedProperties.Type]) {
          obj[trackPrefix][enhancedProperties.Type] = [];
        }

        // Add the enhanced properties (including geometry type) to the respective Type container
        obj[trackPrefix][enhancedProperties.Type].push(enhancedProperties);

        // Filter features to exclude those with Subtype "MidPoint" or "Icon"
        const temp = obj[trackPrefix][enhancedProperties.Type].filter(
          (o) => o.Subtype !== "MidPoint"
        );
        const temp2 = temp.filter((o) => o.Subtype !== "Icon");
        obj[trackPrefix][enhancedProperties.Type] = temp2;
      });

      // Directly reduce obj to create obj2
      Object.entries(obj).forEach(([key, value]) => {
        obj2[key] = Object.values(value).flat();
      });
      // Custom sort function for the geofence data, priotizing polygon geometry and sorting by Type, Track/Hällered subpart, and Seq. Number
      function customSort(a, b) {
        if (a.geometryType === "Polygon" && b.geometryType !== "Polygon")
          return -1;
        if (a.geometryType !== "Polygon" && b.geometryType === "Polygon")
          return 1;
        if (a.Type < b.Type) return 1;
        if (a.Type > b.Type) return -1;
        if (a["Track/Hällered subpart"] < b["Track/Hällered subpart"])
          return -1;
        if (a["Track/Hällered subpart"] > b["Track/Hällered subpart"]) return 1;

        // Sort barriers by "HPG number"
        if (a.Type === "Barrier" && b.Type === "Barrier") {
          return a["HPG number"].localeCompare(b["HPG number"], undefined, {
            numeric: true,
            sensitivity: "base",
          });
        }

        const seqNumA = parseInt(a["Seq. Number"], 10) || 0;
        const seqNumB = parseInt(b["Seq. Number"], 10) || 0;
        return seqNumA - seqNumB;
      }

      // Sort each array in obj2 using the custom sort function
      Object.keys(obj2).forEach((key) => {
        obj2[key].sort(customSort);
      });

      // Sort the keys of 'obj2' alphabetically and structure the sorted data into 'ordered'
      const ordered = Object.keys(obj2)
        .sort()
        .reduce((acc, key) => {
          acc[key] = obj2[key];
          return acc;
        }, {});

      const sitePrefix = config.sitePrefix;
      const sitePrefixFirst = { [sitePrefix]: ordered[sitePrefix] };
      Object.keys(ordered).forEach((track) => {
        if (track !== sitePrefix) {
          sitePrefixFirst[track] = ordered[track];
        }
      });
      // Filter out the "Checkpoints" entry from 'sitePrefixFirst', possibly for special handling or to exclude it from final output.
      const withoutCheckpoints = Object.fromEntries(
        Object.entries(sitePrefixFirst).filter(([key]) => key !== "Checkpoints")
      );

      // Append custom "Checkpoints" to the end of the geofence track list
      const checkpointsLast = {
        ...withoutCheckpoints,
        Checkpoints: sitePrefixFirst["Checkpoints"],
      };
      setGeofenceData(checkpointsLast);
    };
    run().then();
  }, [geofenceOpen]);

  // Toggles the visibility of a track's details in the UI
  const toggleExtend = (trackKey) => {
    let temp = [...extended];
    if (temp.includes(trackKey)) {
      temp = temp.filter((o) => o != trackKey);
    } else {
      temp.push(trackKey);
    }
    setExtended(temp);
  };

  // Handles the selection state of individual geofence subitems within a track
  const handleChecking = (trackKey, subKey) => {
    const temp = { ...checkedData };
    if (temp[trackKey] && temp[trackKey].includes(subKey)) {
      temp[trackKey] = temp[trackKey].filter((o) => o !== subKey);
    } else {
      if (!temp[trackKey]) {
        temp[trackKey] = [];
      }
      temp[trackKey].push(subKey);
    }
    setCheckedData(temp);
  };
  // Selects or deselects all subitems within a track
  const selectAllCheck = (val, trackKey) => {
    const temp = { ...checkedData };
    if (trackKey) {
      if (temp[trackKey]) {
        delete temp[trackKey];
      } else {
        temp[trackKey] = geofenceData[trackKey].map((e) => e.Autocad_layer);
      }
      setCheckedData(temp);
    }
  };
  /*
    setUniqueNameArray(Object.keys(checkedData).reduce(function(res, v) {
          return res.concat(checkedData[v]);
      }, []))

    */

  // Closes the geofence display modal and clears any selections
  const handleCloseClick = () => {
    handleModalOpen(false);
    setUniqueNameArray([]);
  };

  // Generates a human-readable label for each geofence subitem based on its properties
  function subItemLabel(subItem) {
    let label = "";
    const type = subItem.Type;
    const track = subItem.TrackPrefix;
    const subpart = subItem["Track/Hällered subpart"];
    const num = subItem["Seq. Number"] ? subItem["Seq. Number"] : "";
    const subtype =
      subItem.Subtype && subItem.Subtype === "SpeedZone" ? "(SpeedZone)" : "";
    const string =
      type +
      ": " +
      track +
      (
        <span className="bold">
          {track} {subpart} {num}
        </span>
      );
    if (type === "Barrier") {
      label =
        type + ": " + subItem["HPG number"] + " (" + subItem.Subtype + ")";
    }
    if (type === "Line") {
      label = "Path: " + track + " " + subpart + " " + num;
    }
    if (type === "Polygon") {
      label = "Area: " + track + " " + subpart + " " + num + " " + subtype;
    }
    return !!label ? label : subItem.TMS_Name.replace(/(\s-\s\d{10,})/g, "");
  }
  // Main container for the geofence component
  return (
    <div className={styles.geofence}>
      <span onClick={handleCloseClick} className={styles.closeModal}>
        x
      </span>
      <div className={styles.geofenceMain}>
        <h4>Geo-Fence Display</h4>
        <ul className="outer-ul-gf">
          {Object.keys(geofenceData).map((trackKey) => {
            const length = checkedData[trackKey]
              ? Object.keys(checkedData[trackKey]).length > 0
              : false;
            return (
              <li key={trackKey} className={styles.listItem}>
                <div className={styles.toggleContainer}>
                  <input
                    onChange={(e) => selectAllCheck(e.target.value, trackKey)}
                    type="checkbox"
                    name=""
                    checked={length}
                    id=""
                    className={styles.checkbox}
                  />
                  <div
                    className={styles.contentAndArrow}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExtend(trackKey);
                    }}
                  >
                    <span className={styles.bold}>
                      {trackKey !== "Checkpoints" ? "Track: " : ""}
                      {trackKey}
                    </span>
                    <span className={styles.arrow}>
                      <Image
                        className={`${extended.includes(trackKey) ? styles.rotate : ""}`}
                        src="/arrow.svg"
                        alt=""
                        width={18}
                        height={18}
                      />
                    </span>
                  </div>
                </div>
                <ul
                  onClick={(e) => e.stopPropagation()}
                  className={`${extended.includes(trackKey) ? styles.shown : styles.hidden} ${styles.innerUlGf}`}
                >
                  {geofenceData[trackKey] &&
                  geofenceData[trackKey].length > 0 ? (
                    geofenceData[trackKey].map((subItem) => {
                      const name = subItem.Autocad_layer ?? subItem.TMS_Name;
                      return (
                        <li title={name} key={name}>
                          <input
                            onChange={(e) => {
                              handleChecking(trackKey, name);
                            }}
                            type="checkbox"
                            name=""
                            checked={
                              checkedData[trackKey] &&
                              checkedData[trackKey].includes(name)
                            }
                            id=""
                          />{" "}
                          <b>{subItemLabel(subItem)}</b>
                        </li>
                      );
                    })
                  ) : (
                    <li> CHECKPOINTS NOT SPECIFIED</li>
                  )}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="menu-info-button"></div>
    </div>
  );
}

export default Geofence;
