import { useContext, useState, useMemo } from "react";
import { Marker } from "react-map-gl";
import selectedTrackerTabContext from "../../../selectedTrackerTabContext";
import { useSelector } from "react-redux";
import MarkerPopup from "../../PopUp/TrackerMarkerPopUp";
import "../../PopUp/TrackerMarkerPopUp.module.scss";
import { add, remove, toggle } from "../../../trackerWindowsSlice";
import { remove as removeIcon } from "./trackerSlice";

import reduxStore from "../../../reduxStore";
import store from "../../../reduxStore";
import { useAppSelector } from "../../../hooks/reduxHooks";

export default function TrackerMarker(props) {
  const { tracker, categories, config } = props;

  const trackerWindows = useSelector((store) => store.trackerWindows);
  //console.log(trackerWindows)
  const [trackerWindowVisible, setTrackerWindowVisible] = useState(false);
  useMemo(() => {
    const showTrackerWindow = trackerWindows.indexOf(tracker.IMEI) >= 0;
    // console.log(showTrackerWindow)
    setTrackerWindowVisible(showTrackerWindow);
  }, [trackerWindows]);

  const openTrackerWindow = (val) => reduxStore.dispatch(add(val));
  const closeTrackerWindow = (val) => reduxStore.dispatch(remove(val));
  const toggleTrackerWindow = (val) => reduxStore.dispatch(toggle(val));

  const [alarms, setAlarms] = useState();
  // const [selectedTrackerTab, setSelectedTrackerTab] = useContext(selectedTrackerTabContext)
  const selectedTrackerTab = useAppSelector((state) => state.hoveredTracker);

  // console.log(tracker)
  //console.log(tracker)

  const lat = parseFloat(tracker.Latitude);
  const long = parseFloat(tracker.Longitude);

  // let icon = getIcon(tracker.Status, tracker.Category);
  const icon = useMemo(() => {
    let icon = categories
      ? categories[tracker.Category]?.icon
      : "RedDiamondWhiteCenter.png";

    if (tracker.alarmTime > tracker.alarmAckTime) {
      icon = "RedDiamond.png";
    }
    if (icon === undefined) {
      icon = categories.Default.icon;
    }
    if (!icon.includes(".png")) {
      icon = icon + ".png";
    }
    icon = icon.replace(" ", "");
    return icon;
  }, [tracker]);

  //console.log(icon);
  //console.log(icon);

  //console.log(iconUrl);
  const key = tracker.IMEI;

  const trackView = useSelector((state) => state.currentTrack);
  const fenceArrayLength = tracker.Track?.length;
  let trackTest = true;
  if (fenceArrayLength > 0) {
    trackTest = tracker.Track[fenceArrayLength - 1].TrackPrefix === trackView;
  }
  if (trackView === "HPG") {
    trackTest = true;
  }

  function deleteFromMap(imei) {
    //console.log(imei);
    closeTrackerWindow(imei);
    store.dispatch(removeIcon(imei));
  }

  function isInactive(tracker) {
    if (tracker.Status === "Inactive") {
      deleteFromMap(tracker.IMEI);
      return true;
    } else {
      return false;
    }
  }

  const image = useMemo(() => {
    let iconUrl = config.url.API + "/iconFiles/" + icon;
    return selectedTrackerTab == tracker.IMEI ? (
      <img width="50" height="50" title={tracker.ID} src={`${iconUrl}`} />
    ) : (
      <img title={tracker.ID} src={`${iconUrl}`} />
    );
  }, [icon, tracker, selectedTrackerTab]);

  return (
    <>
      {trackTest && !isInactive(tracker) ? (
        <Marker
          longitude={long}
          latitude={lat}
          name={tracker.ID}
          onClick={() => {
            toggleTrackerWindow(tracker.IMEI);
          }}
        >
          {image}
        </Marker>
      ) : null}
    </>
  );
}
