import { Marker, useMap } from "react-map-gl";
import * as React from "react";
import { useSelector } from "react-redux";
// import Image from 'next/image'
import ccw from "../../../../public/dir_ccw.png";
import cw from "../../../../public/dir_cw.png";
import { useMemo, useState } from "react";

export default function DirectionMarker(props) {
  const {
    latitude,
    longitude,
    rotation,
    direction,
    track,
    visible,
    allTrackViews,
  } = props;

  const lat = parseFloat(latitude);
  const lon = parseFloat(longitude);

  const map = useMap().current;
  // const ccw = './dir_ccw.png'
  // const cw = './dir_cw.png'
  let dirIcon = "cw";
  if (direction === "CounterClockwise") {
    dirIcon = "ccw";
  }
  if (direction === "Both") {
    dirIcon = "";
  }
  const trackView = useSelector((state) => state.currentTrack);
  let trackTest = track === trackView;
  if (trackView === "HPG") {
    trackTest = true;
  }

  let dirScale = 1;
  if (trackView === "HPG") {
    dirScale = 0.75;
  }
  const [currentRotation, setCurrentRotation] = useState(
    rotation + allTrackViews[trackView].bearing
  );

  useMemo(() => {
    setCurrentRotation(rotation + allTrackViews[trackView].bearing);
  }, [trackView]);

  return (
    <>
      {trackTest ? (
        <Marker
          style={{ display: visible ? "inherit" : "none" }}
          longitude={lon}
          latitude={lat}
          rotation={currentRotation}
          rotationAlignment={"map"}
        >
          <img
            src={"./dir_" + dirIcon + ".png"}
            height={45 * dirScale}
            width={45 * dirScale}
          />
        </Marker>
      ) : null}
    </>
  );
}
